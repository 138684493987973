<template>
  <v-main>
    <v-row gutters="2" justify="end">
      <v-col cols="12" md="12">
        <Buttons
          :btnCancel="false"
          :textSave="textButton"
          v-on:save="addRoom"
        />
      </v-col>
    </v-row>
    <v-row gutters="2" class="pt-0 pb-0">
      <v-col cols="12" md="12" class="pt-0 pb-0">
        <v-card>
          <v-card-text>
            <v-row no-gutters justify="space-around">
              <v-col cols="12" md="12">
                <RoomTable></RoomTable>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
// Import internal scripts
// Import General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

// Import components
import RoomTable from "@/view/content/components/products_addons/room/Table";
import { mapActions } from "vuex";
import Buttons from "@/view/content/components/Buttons";
export default {
  components: { RoomTable, Buttons },

  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("PRODUCTS_ADDONS.TITLE") },
      { title: this.$t("PRODUCTS_ADDONS.ROOM") },
    ]);
    this.getRooms();
  },
  methods: {
    ...mapActions(["getRooms"]),
    addRoom() {
      this.$router.push({
        name: "product-room-new",
        params: { operation: "new" },
      });
    },
  },
  computed: {
    textButton() {
      return `${this.$i18n.t("PRODUCTS_ADDONS.NEW")}`;
    },
  },
};
</script>

<style scoped>
.btn-outline-primary {
  border-radius: 0;
}
ul {
  padding: 0px !important;
}
</style>
